import { graphql, useStaticQuery } from "gatsby";

export default function useSettings() {
	const data = useStaticQuery(graphql`
    {
      allContentfulSettings {
        nodes {
					title
					description
					shareImage {
						fluid(maxWidth: 800, quality: 70) {
							src
						}
					}
					menu {
						label
						slug
						link {
							__typename
							label: title
							slug
						}
						submenu {
							... on ContentfulProduct {
								id
								label: title
								slug
							}
							... on ContentfulSettingsMenuItem {
								id
								label
								slug
								submenu {
									... on ContentfulProduct {
										id
										slug
										label: title
									}
								}
							}
						}
					}
					legal: customerCareFooter {
						label
						slug
						link {
							__typename
							label: title
							slug
						}
					}
					footerContactCopy {
						childMarkdownRemark {
							html
						}
					}
					footerAddressCopy {
						childMarkdownRemark {
							html
						}
					}
					customiseCopy {
            childMarkdownRemark {
              html
            }
          }
					footerOtherLinksTitle
					footerOtherLinks {
						label
						slug
						link {
							__typename
							label: title
							slug
						}
					}
					contactTelNo
					contactTelLabel
					contactEmail
					contactAddressLabel
					contactAddressLink
				}
      }
    }
  `)
  return data.allContentfulSettings.nodes[0]
}
